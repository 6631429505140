.whitescreen {
    display: none; /* do not display on start */
    z-index: -1000; /* move behind app-root */
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    flex-direction: column;
    justify-content: space-between;
    font-size: 14px;
}
.whitescreen p {
    margin: 0;
}
.whitescreen-top {
    flex-grow: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.whitescreen-icon {
    text-align: center;
    font-size: 48px;
    padding-bottom: 20px;
}
.whitescreen-message {
    line-height: 20px;
}
.whitescreen-email {
    display: block;
    text-align: center;
    padding: 10px;
    color: blue;
}
.whitescreen-bottom {
    padding: 20px;
}
.whitescreen-bottom p {
    font-size: 10px;
    background: #fff;
    color: #000;
}